import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Section from './Section';

export interface PublicationDescription {
  authors: string;
  day: Date;
  title: string;
  journalOrConference: string;
  status: string;
  link: string;
  image: string;
}

export interface AwardsProps {
  publications: Record<string, PublicationDescription>;
}

const Publications: React.FC<AwardsProps> = ({ publications }) => (
  <Section name="Publications">
    <div className="grid gap-2">
      {Object.keys(publications).map((key) => {
        const publication = publications[key];
        return (
          <a href={publication.link} target="_blank" rel="noreferrer">
            <ScrollAnimation
              animateOnce
              animateIn="animate__fadeInUp"
              key={key}
              className="flex gap-2 items-center"
            >
              <img className="w-28" src={publication.image} alt={key} />
              <p className="text-sm my-2">
                {publication.authors} ({publication.day.getFullYear()}).{` `}
                {publication.title}.{` `}
                <i className="italic">{publication.journalOrConference}</i>.
                {` `}
              </p>
            </ScrollAnimation>
          </a>
        );
      })}
    </div>
  </Section>
);

export default Publications;
