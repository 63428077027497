import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useState } from 'react';

export interface ShowreelSlide {
  text: string;
  content:
    | {
        contentType: 'Youtube';
        youTubeLink: string;
      }
    | {
        contentType: 'Image';
        imageLink: string;
        imageAlt: string;
      };
}

const ShowreelSlideComp = ({ slide }: { slide: ShowreelSlide }) => {
  const { t } = useTranslation(`Showreel`);

  return (
    <div className="border border-gray-300 border-solid rounded-lg shadow-lg p-8 w-11/12 lg:w-4/5 flex flex-col items-center">
      {(() => {
        switch (slide.content.contentType) {
          case `Image`:
            return (
              <img
                src={slide.content.imageLink}
                alt={slide.content.imageAlt}
                className="h-[12rem] md:h-[24rem] lg:h-[36rem]"
              />
            );
          case `Youtube`:
            return (
              <iframe
                src={slide.content.youTubeLink}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                className="h-[12rem] md:h-[24rem] lg:h-[36rem] aspect-video w-full"
              />
            );
          default:
            return <span>{slide.content}</span>;
        }
      })()}
      <span>{t(slide.text)}</span>
    </div>
  );
};

export interface ShowreelProps {
  showreels: ShowreelSlide[];
}

const Showreel: React.FC<ShowreelProps> = ({ showreels }) => {
  const [visibleIndex, setVisibleIndex] = useState(0);

  return (
    <div className="relative overflow-hidden w-full h-[24rem] md:h-[40rem] lg:h-[52rem]">
      {showreels.map((slide, i) => {
        let cssClass;
        if (i < visibleIndex) {
          cssClass = `left-[-50%]`;
        } else if (i === visibleIndex) {
          cssClass = `left-1/2`;
        } else {
          cssClass = `left-[150%]`;
        }
        return (
          <div
            className={`absolute block w-full h-full -translate-x-1/2 -translate-y-1/2 top-1/2 duration-700 ease-in-out ${cssClass} flex justify-center items-center`}
          >
            <ShowreelSlideComp slide={slide} />
          </div>
        );
      })}
      <div className="absolute z-30 flex space-x-3 -translate-x-1/2 bottom-5 left-1/2">
        {showreels.map((slide, i) =>
          i === visibleIndex ? (
            <button type="button" className="w-3 h-3 rounded-full">
              &#x25CF;
            </button>
          ) : (
            <button type="button" className="w-3 h-3 rounded-full">
              &#x25CB;
            </button>
          ),
        )}
      </div>
      <button
        type="button"
        className="absolute top-0 left-0 z-30 flex items-center justify-center h-full cursor-pointer group focus:outline-none"
        onClick={() => {
          setVisibleIndex(
            (visibleIndex + showreels.length - 1) % showreels.length,
          );
        }}
      >
        <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white border border-gray-200 border-solid shadow">
          <svg
            className="w-4 h-4 text-black dark:text-gray-800"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 1 1 5l4 4"
            />
          </svg>
          <span className="sr-only">Previous</span>
        </span>
      </button>
      <button
        type="button"
        className="absolute top-0 right-0 z-30 flex items-center justify-center h-full cursor-pointer group focus:outline-none"
        onClick={() => {
          setVisibleIndex(
            (visibleIndex + showreels.length + 1) % showreels.length,
          );
        }}
      >
        <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white border border-gray-200 border-solid shadow">
          <svg
            className="w-4 h-4 text-black"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 9 4-4-4-4"
            />
          </svg>
          <span className="sr-only">Next</span>
        </span>
      </button>
    </div>
  );
};

export default Showreel;
