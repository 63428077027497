import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Section from './Section';
import Time from './Time';

export interface ProjectDescription {
  name: string;
  startDate: Date;
  endDate: Date;
  tags: string[];
  image: string;
  links?: {
    name: string;
    url: string;
  }[];
}

export interface ProjectProps {
  project: ProjectDescription;
}

export interface ProjectsProps {
  projects: ProjectDescription[];
}

const Projects: React.FC<ProjectsProps> = ({ projects }) => {
  const { t } = useTranslation(`Projects`);

  return (
    <Section name="Projects">
      {projects.map((project) => (
        <ScrollAnimation
          animateOnce
          animateIn="animate__fadeInUp"
          className="rounded border-solid border border-gray-400 shadow-md my-2 p-3"
        >
          <div className="grid grid-cols-[6em_1fr] gap-4 items-center">
            <img className="grid" src={project.image} alt={project.name} />
            <div className="grid gap-2">
              <div className="flex flex-wrap justify-between">
                <span className="text-lg">{t(project.name)}</span>
                <Time from={project.startDate} to={project.endDate} />
              </div>
              <p className="">{t(`${project.name}Desc`)}</p>
              {/* <div className="flex flex-wrap gap-2">
                {project.tags.map((tag) => (
                  <span className="rounded bg-sky-200 px-3">{t(tag)}</span>
                ))}
              </div> */}
              <div className="flex flex-wrap gap-2">
                {project.links?.map((link, i) => (
                  <>
                    <a
                      className="text-blue-700 hover:underline px-4 first:pl-0"
                      href={link.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t(link.name)}
                    </a>
                    {i < project.links.length - 1 && (
                      <span className="text-gray-400">•</span>
                    )}
                  </>
                ))}
              </div>
            </div>
          </div>
        </ScrollAnimation>
      ))}
    </Section>
  );
};

export default Projects;
