import { EducationDescription } from '@/components/Education';
import RWTHLogo from './education/RWTH.png';
import UWFLogo from './education/UWF.png';

export const EducationContent: Record<string, EducationDescription> = {
  MSCompSci: {
    startingDay: new Date(2020, 7, 24, 0, 0, 0, 0),
    graduationDay: new Date(2022, 4, 7, 0, 0, 0, 0),
    awardedBy: `University of West Florida`,
    institutionLogo: UWFLogo,
    specialNotes: [
      `UWFPresidentOfAIResearchGroup`,
      `UWFOutstandingGraduateStudent`,
      `UWFCPTAtIHMC`,
    ],
    grade: `3.93`,
    bestGrade: `4.0`,
    worstGrade: `1.0`,
  },
  BSEtitti: {
    startingDay: new Date(2016, 9, 10, 0, 0, 0, 0),
    graduationDay: new Date(2020, 2, 9, 0, 0, 0, 0),
    awardedBy: `RWTH Aachen`,
    institutionLogo: RWTHLogo,
    specialNotes: [`BSEtittiThesis`],
    grade: `2.5`,
    bestGrade: `1.0`,
    worstGrade: `5.0`,
  },
  BSWing: {
    startingDay: new Date(2016, 9, 10, 0, 0, 0, 0),
    graduationDay: new Date(2020, 4, 20, 0, 0, 0, 0),
    awardedBy: `RWTH Aachen`,
    institutionLogo: RWTHLogo,
    specialNotes: [`BSWingThesis`, `BSWingDeanslist`],
    grade: `2.0`,
    bestGrade: `1.0`,
    worstGrade: `5.0`,
  },
};

export default EducationContent;
