import React from 'react';
import { graphql, PageProps } from 'gatsby';

import Title from '@/components/Title';

// import Skills from '@/components/Skills';
// import SkillsContent from '@/content/Skills';
import Layout from '@/components/Layout';
import Projects from '@/components/Projects';
import ProjectContent from '@/content/Projects';
import Employment from '@/components/Employment';
import EmplomentContent from '@/content/Employment';
import Awards from '@/components/Awards';
import AwardsContent from '@/content/Awards';
import Education from '@/components/Education';
import EducationContent from '@/content/Education';
import Publications from '@/components/Publications';
import PublicationsContent from '@/content/Publications';
import SocialMedia from '@/components/SocialMedia';
// import Freelance from '@/components/Freelance';
import 'animate.css';
import Showreel from '@/components/Showreel';
import ShowreelContent from '@/content/Showreel';

const Home: React.FC<PageProps> = () => (
  <Layout>
    <Title />
    {/* <Freelance /> */}
    <Showreel showreels={ShowreelContent} />
    <div className="mb-8" />
    {/* <Skills skills={SkillsContent} />
    <div className="mb-8" /> */}
    <Projects projects={ProjectContent} />
    <div className="mb-8" />
    <Employment employers={EmplomentContent} />
    <div className="mb-8" />
    <Awards awards={AwardsContent} />
    <div className="mb-8" />
    <Education educations={EducationContent} />
    <div className="mb-8" />
    <Publications publications={PublicationsContent} />
    <div className="mb-8" />
    <SocialMedia />
    <div className="mb-32" />
  </Layout>
);

export default Home;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
