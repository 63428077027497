import { ShowreelSlide } from '@/components/Showreel';

import GameControllerImage from './projectImagesLarge/GameController.png';
import AITracksAtSeaImage from './projectImagesLarge/AITracksAtSea.png';

const ShowreelContent: ShowreelSlide[] = [
  {
    text: `FourAnyone`,
    content: {
      contentType: `Youtube`,
      youTubeLink: `https://www.youtube.com/embed/RpphFszxQbM`,
    },
  },
  {
    text: `FourAnyone`,
    content: {
      contentType: `Youtube`,
      youTubeLink: `https://www.youtube.com/embed/bdVrWxjK2vo`,
    },
  },
  {
    text: `FourAnyone`,
    content: {
      contentType: `Youtube`,
      youTubeLink: `https://www.youtube.com/embed/GE7VDgR_J1g`,
    },
  },
  {
    text: `Armar6Teleoperation`,
    content: {
      contentType: `Youtube`,
      youTubeLink: `https://www.youtube.com/embed/bsUIxaQF9Pg`,
    },
  },
  {
    text: `AtlasWalking`,
    content: {
      contentType: `Youtube`,
      youTubeLink: `https://www.youtube.com/embed/6vOCj1EfiCo`,
    },
  },
  {
    text: `GameController`,
    content: {
      contentType: `Image`,
      imageLink: GameControllerImage,
      imageAlt: `An image of a Game Controller PCB Board`,
    },
  },
  {
    text: `AITracksAtSeaChallenge`,
    content: {
      contentType: `Image`,
      imageLink: AITracksAtSeaImage,
      imageAlt: `An image of a vessel in the sea`,
    },
  },
  {
    text: `BluePilot`,
    content: {
      contentType: `Youtube`,
      youTubeLink: `https://www.youtube.com/embed/gir5exnnqjY`,
    },
  },
  {
    text: `AtlasReinforcementLearning`,
    content: {
      contentType: `Youtube`,
      youTubeLink: `https://www.youtube.com/embed/We3YGA6GziA`,
    },
  },
  {
    text: `DronesCaptureTheFlag`,
    content: {
      contentType: `Youtube`,
      youTubeLink: `https://www.youtube.com/embed/YKXLaFIX0Sk`,
    },
  },
];

export default ShowreelContent;
