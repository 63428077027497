import { ProjectDescription } from '@/components/Projects';
import AtlasRLImage from './projectImages/AtlasInPyBullet.png';
import DroneCTFImage from './projectImages/DroneCTF.png';
import AITracksAtSeaImage from './projectImages/AITracksAtSea.png';
import Armar6TeleoperationImage from './projectImages/Armar6Teleoperation.png';
import AvailabilityToolImage from './projectImages/AvailabilityTool.png';
import GameControllerImage from './projectImages/GameController.png';
import BluePilotImage from './projectImages/BluePilot.png';

const ProjectContent: ProjectDescription[] = [
  {
    name: `ArmarTeleoperation`,
    startDate: new Date(2023, 2, 1, 0, 0, 0, 0),
    endDate: new Date(2023, 4, 1, 0, 0, 0, 0),
    tags: [`data-collection`, `robotics`],
    image: Armar6TeleoperationImage,
    links: [
      {
        name: `Youtube Video 1`,
        url: `https://youtu.be/hVprn7XwRkk`,
      },
      {
        name: `Youtube Video 2`,
        url: `https://youtu.be/ePDog9qVYr0`,
      },
    ],
  },
  {
    name: `GameController`,
    startDate: new Date(2022, 9, 2, 0, 0, 0, 0),
    endDate: new Date(2023, 0, 10, 0, 0, 0, 0),
    tags: [`hardware`, `simulation`, `usb`],
    image: GameControllerImage,
    links: [
      {
        name: `Github Repository Configurator`,
        url: `https://github.com/TobiasJacob/gcrdeviceconfigurator`,
      },
    ],
  },
  {
    name: `AtlasRL`,
    startDate: new Date(2021, 7, 23, 0, 0, 0, 0),
    endDate: new Date(2021, 11, 11, 0, 0, 0, 0),
    tags: [`reinforcement-learning`, `robotics`, `internship`],
    image: AtlasRLImage,
    links: [
      {
        name: `Youtube Video 1`,
        url: `https://youtu.be/We3YGA6GziA`,
      },
      {
        name: `Youtube Video 2`,
        url: `https://youtu.be/6vOCj1EfiCo`,
      },
    ],
  },
  {
    name: `DroneCTF`,
    startDate: new Date(2021, 4, 17, 0, 0, 0, 0),
    endDate: new Date(2021, 7, 13, 0, 0, 0, 0),
    tags: [`reinforcement-learning`, `robotics`, `internship`],
    image: DroneCTFImage,
    links: [
      {
        name: `Youtube Video`,
        url: `https://youtu.be/YKXLaFIX0Sk `,
      },
    ],
  },
  {
    name: `AITrackAtSea`,
    startDate: new Date(2020, 10, 7, 0, 0, 0, 0),
    endDate: new Date(2020, 11, 2, 0, 0, 0, 0),
    tags: [`computer vision`, `competition`],
    image: AITracksAtSeaImage,
    links: [
      {
        name: `News Article`,
        url: `https://news.uwf.edu/two-uwf-teams-place-in-top-5-in-national-artificial-intelligence-competition/`,
      },
    ],
  },
  {
    name: `BluePilot`,
    startDate: new Date(2020, 10, 7, 0, 0, 0, 0),
    endDate: new Date(2020, 11, 2, 0, 0, 0, 0),
    tags: [`control`],
    image: BluePilotImage,
    links: [
      {
        name: `Youtube Video`,
        url: `https://youtu.be/gir5exnnqjY`,
      },
    ],
  },
  {
    name: `AvailabilityTool`,
    startDate: new Date(2018, 4, 9, 0, 0, 0, 0),
    endDate: new Date(2018, 6, 20, 0, 0, 0, 0),
    tags: [`office`],
    image: AvailabilityToolImage,
  },
];

export default ProjectContent;
