import React, { useState } from 'react';
import { Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';

const NavBar: React.FC = () => {
  const { languages, originalPath } = useI18next();
  const [navVisible, setNavVisible] = useState(true);
  const { t } = useTranslation(`Sections`);

  const toggleNav = () => {
    if (navVisible) {
      setNavVisible(false);
    } else {
      setNavVisible(true);
    }
  };

  return (
    <nav className="border-gray-200 px-2 sm:px-4 py-2.5">
      <div className="container flex flex-wrap justify-between items-center mx-auto">
        <button
          data-collapse-toggle="mobile-menu"
          type="button"
          className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 md:hidden"
          aria-controls="mobile-menu"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            onClick={toggleNav}
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <div
          className={`w-full md:block md:w-auto ${navVisible && `hidden`}`}
          id="mobile-menu"
        >
          <ul className="flex flex-col content-center mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
            {languages.map((lng) => (
              <li key={lng}>
                <Link
                  to={originalPath}
                  language={lng}
                  className="block text-xl pr-4 pl-3"
                >
                  {getUnicodeFlagIcon(
                    {
                      en: `US`,
                      de: `DE`,
                    }[lng],
                  )}
                </Link>
              </li>
            ))}
            {[
              `Skills`,
              `Projects`,
              `Employment`,
              `Awards`,
              `Education`,
              `Publications`,
              `SocialMedia`,
            ].map((section) => (
              <li>
                <Link to={`#${section}`} className="block py-2 pr-4 pl-3">
                  {t(section)}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
