import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from 'react-i18next';

const Title: React.FC = () => {
  const { t } = useTranslation(`Title`);

  return (
    <section
      className="grid content-center justify-items-center py-8"
      id="title"
    >
      <ScrollAnimation animateIn="animate__fadeIn" animateOnce delay={100}>
        <h1 className="text-6xl font-bold text-center mb-4">Tobias Jacob</h1>
      </ScrollAnimation>
      <ScrollAnimation animateIn="animate__fadeIn" animateOnce delay={200}>
        <h2 className="text-2xl text-center mb-4">{t(`Subheading`)}</h2>
      </ScrollAnimation>
      <ScrollAnimation animateIn="animate__fadeIn" animateOnce delay={300}>
        <p className="text-center">{t(`Caption`)}</p>
      </ScrollAnimation>
    </section>
  );
};

export default Title;
