import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Section from './Section';
import Time from './Time';

export interface AwardDescription {
  name: string;
  day: Date;
  price?: string;
  place: number;
  participants: number;
  awardedBy: string;
  link?: string;
}

export interface AwardsProps {
  awards: Record<string, AwardDescription>;
}

const Awards: React.FC<AwardsProps> = ({ awards }) => {
  const { t } = useTranslation(`Awards`);

  return (
    <Section name="Awards">
      <div className="flex flex-wrap mt-2 gap-4 justify-center">
        {Object.keys(awards).map((key) => {
          const award = awards[key];
          return (
            <ScrollAnimation
              animateOnce
              animateIn="animate__fadeInUp"
              key={key}
              className="border border-solid border-gray-600 p-1 bg-amber-50 w-96"
            >
              <div className="border border-solid border-gray-600 p-2 rounded text-center w-full h-full">
                <Time from={award.day} />
                <p className="text-lg mt-2">{award.name}</p>
                <span className="text-normal">
                  {award.place} / {award.participants}
                </span>
                {award.price && (
                  <span className="text-normal">- {award.price}</span>
                )}
                <p className="text-sm my-2">{t(`${key}Desc`)}</p>
              </div>
            </ScrollAnimation>
          );
        })}
      </div>
    </Section>
  );
};

export default Awards;
