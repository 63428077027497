import { AwardDescription } from '@/components/Awards';

export const AwardsContent: Record<string, AwardDescription> = {
  ICPC: {
    name: `ICPC Regionals Div. 2`,
    day: new Date(2022, 2, 5, 0, 0, 0, 0),
    place: 3,
    participants: 33,
    awardedBy: `ICPC Foundation`,
    link: `https://news.uwf.edu/uwf-team-earns-third-place-in-computer-programming-competition/`,
  },
  HackTheMachineTabular: {
    name: `HackTheMachine (Data Science)`,
    day: new Date(2021, 10, 19, 0, 0, 0, 0),
    price: `$15000`,
    place: 1,
    participants: 43,
    awardedBy: `Fathom5`,
  },
  HackTheMachineNLP: {
    name: `HackTheMachine (Data Science)`,
    day: new Date(2021, 2, 26, 0, 0, 0, 0),
    place: 5,
    participants: 20,
    awardedBy: `Fathom5`,
  },
  AITracksAtSea: {
    name: `AI tracks at sea`,
    day: new Date(2021, 1, 27, 0, 0, 0, 0),
    price: `$35000`,
    place: 3,
    participants: 41,
    awardedBy: `Naval Information Warfare Center Pacific`,
    link: `https://news.uwf.edu/two-uwf-teams-place-in-top-5-in-national-artificial-intelligence-competition/`,
  },
  NCL: {
    name: `National Cyber Leauge`,
    day: new Date(2020, 11, 18, 0, 0, 0, 0),
    place: 28,
    participants: 6011,
    awardedBy: `Cyber Skyline`,
  },
};

export default AwardsContent;
