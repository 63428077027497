import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Section from './Section';
import Time from './Time';

export interface EducationDescription {
  startingDay: Date;
  graduationDay: Date;
  institutionLogo: string;
  awardedBy: string;
  specialNotes: string[];
  grade: string;
  bestGrade: string;
  worstGrade: string;
}

export interface EducationProps {
  educations: Record<string, EducationDescription>;
}

const Education: React.FC<EducationProps> = ({ educations }) => {
  const { t } = useTranslation(`Education`);

  return (
    <Section name="Education">
      <div className="grid">
        {Object.keys(educations).map((key) => {
          const education = educations[key];
          return (
            <ScrollAnimation
              animateOnce
              animateIn="animate__fadeInUp"
              key={key}
              className="flex items-start gap-4 mt-4"
            >
              <img
                className="w-20 py-1"
                src={education.institutionLogo}
                alt={education.awardedBy}
              />
              <div>
                <div className="flex flex-col items-start">
                  <span className="text-lg font-bold mr-4">{t(key)}</span>
                  <Time
                    from={education.startingDay}
                    to={education.graduationDay}
                  />
                </div>
                <p className="">
                  {t(`GPA`)} {education.grade} ({t(`best`)}:{` `}
                  {education.bestGrade}
                  {` `}- {t(`worst`)}: {education.worstGrade})
                </p>
                <ul className="list-disc pl-4">
                  {education.specialNotes.map((note) => (
                    <li>{t(note)}</li>
                  ))}
                </ul>
              </div>
            </ScrollAnimation>
          );
        })}
      </div>
    </Section>
  );
};

export default Education;
