import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

export interface ProjectsProps {
  name: string;
}

const Section: React.FC<ProjectsProps> = ({ name, children }) => {
  const { t } = useTranslation(`Sections`);
  return (
    <section className="" id={name}>
      <ScrollAnimation animateIn="animate__fadeIn" animateOnce delay={100}>
        <h2 className="text-2xl font-bold mb-2">{t(name)}</h2>
      </ScrollAnimation>
      {children}
    </section>
  );
};

export default Section;
