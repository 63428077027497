import { PublicationDescription } from '@/components/Publications';
import AITracksAtSeaImage from './publications/AITracksAtSea.png';
import RobustOpenFieldTrackingImage from './publications/RobustOpenFieldTracking.png';
import DronesCaptureTheFlag from './publications/DroneCTF.png';

export const PublicationsContent: Record<string, PublicationDescription> = {
  DroneCaptureTheFlag: {
    authors: `Tobias Jacob, Daniel Duran, Trey Pfeiffer, Micael Vignati, Matthew Johnson`,
    day: new Date(2023, 10, 5, 0, 0, 0, 0),
    title: `Multi-agent Reinforcement Learning for Unmanned Aerial Vehicle Capture-The-Flag Game Behavior`,
    journalOrConference: `Intellisys 2023`,
    status: `Accepted`,
    link: `https://youtu.be/YKXLaFIX0Sk`,
    image: DronesCaptureTheFlag,
  },
  AITracksAtSea: {
    authors: `Tobias Jacob, Raffaele Galliera, Muddasar Ali, Sikha Bagui`,
    day: new Date(2021, 8, 23, 0, 0, 0, 0),
    title: `Marine Vessel Tracking using a Monocular Camera`,
    journalOrConference: `Delta 2021`,
    status: `Published`,
    link: `https://arxiv.org/abs/2108.10367`,
    image: AITracksAtSeaImage,
  },
  RobustOpenFieldTracking: {
    authors: `Marcin Kopaczka, Tobias Jacob, Lisa Ernst, Mareike Schulz, René Tolba, Dorit Merhof`,
    day: new Date(2020, 2, 12, 0, 0, 0, 0),
    title: `Robust Open Field Rodent Tracking Using a Fully Convolutional Network and a Softargmax Distance Loss`,
    journalOrConference: `Bildverarbeitung für die Medizin 2020`,
    status: `Published`,
    link: `https://link.springer.com/chapter/10.1007/978-3-658-29267-6_54`,
    image: RobustOpenFieldTrackingImage,
  },
};

export default PublicationsContent;
