import React from 'react';
import Section from './Section';

const SocialMedia: React.FC = () => (
  <Section name="SocialMedia">
    <a
      className="underline"
      href="https://www.linkedin.com/in/tobias-jacob-811b62189?trk=profile-badge"
    >
      LinkedIn: Tobias Jacob
    </a>
    {` `}●{` `}
    <a className="underline" href="https://github.com/TobiasJacob/">
      Github: TobiasJacob
    </a>
    {` `}●{` `}
    <a className="underline" href="https://orcid.org/0000-0002-8530-258X">
      Orcid: 0000-0002-8530-258X
    </a>
  </Section>
);

export default SocialMedia;
