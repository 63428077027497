import React from 'react';
import { Helmet } from 'react-helmet';
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
import NavBar from './Navbar';

export default function Layout({ children }) {
  const { t } = useTranslation(`Title`);
  const context = React.useContext(I18nextContext);
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: context.language,
        }}
      >
        <meta charSet="utf-8" />
        <title>{t(`SEOTitle`)}</title>
        <meta name="description" content={t(`SEODescription`)} />
        <meta name="author" content="Tobias Jacob" />
        <meta name="keywords" content={t(`MetaTags`)} />
        <meta charSet="utf-8" />
      </Helmet>
      <NavBar />
      <main className="container mx-auto px-4">{children}</main>
    </>
  );
}
